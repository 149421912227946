<template>
  <div class="w-full">
    <SuccessButton
      :disabled="!$atividades.permissoes.includes(14)"
      class="mr-6"
      @func="modalCadastroProveniente = true"
    ></SuccessButton>
    <SideBar
      title="Cadastrar Proveniente"
      @hide="modalCadastroProveniente = false"
      :active="modalCadastroProveniente"
      size="small"
      @success="submitForm()"
    >
      <div
        slot="body"
        id="proveniente-cadastro"
        class="vs-con-loading__container w-full"
      >
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              name="nomeProveniente"
              v-validate="'required'"
              label-placeholder="Nome"
              v-model="proveniente.nome"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('nomeProveniente')"
              >{{ $validators.empty }}</span
            >
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"

export default {
  data () {
    return {
      proveniente: {},
      modalCadastroProveniente: false
    }
  },
  methods: {
    async submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cadastrarProveniente()
        } else {
          this.$vs.notify(this.$notify.Empty)
        }
      })
    },
    async cadastrarProveniente () {
      await this.$vs.loading({
        container: "#proveniente-cadastro",
        scale: 0.6
      })
      try {
        const res = await this.$http.post("proviniente", this.proveniente)
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'cadastrar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Cadastro do proveniente N°' + res.id
        }
        await this.$logger(logData)
        this.proveniente = {}
        this.$vs.notify(this.$notify.Success)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.modalCadastroProveniente = false
        await this.$emit("update")
        await this.$vs.loading.close(
          "#proveniente-cadastro > .con-vs-loading"
        )
      }
    }
  },
  components: components
};
</script>

<style></style>
