<template>
  <div id="proveniente-lista" class="vs-con-loading__container w-full">
    <vs-table
      noDataText=""
      search
      pagination
      max-items="10"
      :data="provenientes"
    >
      <template slot="header">
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <h4 style="color: #b4aa99">Provenientes</h4>
          </vs-col>
          <vs-col vs-w="6">
            <ModalCadastroProveniente @update="getProvenientes" />
          </vs-col>
        </vs-row>
      </template>

      <template slot="thead">
        <vs-th sort-key="id">Nome</vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :state="row.lixeira == 1 ? 'danger' : ''"
          v-for="(row, index) in data"
          :key="index"
        >
          <vs-td :data="row.nome">{{ row.nome }}</vs-td>
          <vs-td class="actions">
            <ModalEditarProveniente
              :proveniente="row"
              @update="getProvenientes"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-if="provenientes.length == 0">
      <div class="con-colors">
        <ul>
          <li class="danger-box">
            <h2 class="p-5" style="color: white !important">
              Nenhum proveniente cadstrado
            </h2>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCadastroProveniente from "./components/cadastro.vue"
import ModalEditarProveniente from "./components/editar.vue"
import components from "@/components/default/exports.js"
export default {
  data () {
    return {
      provenientes: []
    }
  },
  methods: {
    async getProvenientes () {
      await this.$vs.loading({
        container: "#proveniente-lista",
        scale: 0.6
      })
      try {
        this.provenientes = await this.$http.get(`proviniente`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#proveniente-lista > .con-vs-loading"
        )
      }
    }
  },
  components: {
    ModalCadastroProveniente,
    ModalEditarProveniente,
    ...components
  },
  mounted () {
    this.getProvenientes()
  }
};
</script>

<style>
.col {
  width: auto;
}
.actions {
  width: 60px !important;
}
</style>
