<template>
  <div>
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="modalEditarProveniente = true"
    ></EditButton>
    <SideBar
      title="Editar Proveniente"
      @hide="cancelar"
      :active="modalEditarProveniente"
      textSucces="Atualizar"
      icon="edit"
      size="small"
      :disabled="!$atividades.permissoes.includes(15)"
      :item="proveniente"
      @excluir="excluirProveniente()"
      @status="mudarStatusProveniente()"
      @success="submitForm()"
    >
      <div slot="body">
        <div
          :id="'proveniente-editar' + proveniente.id"
          class="vs-con-loading__container w-full"
        >
          <vs-row class="mb-4" vs-type="flex" vs-w="12">
            <vs-col vs-w="12">
              <vs-input
                class="inputx w-full"
                name="nomeProveniente"
                v-validate="'required'"
                label-placeholder="Nome"
                v-model="proveniente.nome"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('nomeProveniente')"
                >{{ $validators.empty }}</span
              >
            </vs-col>
          </vs-row>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"

export default {
  props: { proveniente: Object },
  data () {
    return {
      modalEditarProveniente: false,
      originalData: {}
    }
  },
  async mounted () {
    this._beforeEditingCache = Object.assign({}, this.proveniente)
    this.originalData = this.proveniente
  },
  methods: {
    async submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarproveniente()
        } else {
          this.error = true
          this.$vs.notify(this.$notify.Empty)
        }
      })
    },
    async editarproveniente () {
      await this.$vs.loading({
        container: "#proveniente-editar" + this.proveniente.id,
        scale: 0.6
      })
      try {
        const res = await this.$http.put(`proviniente/${this.proveniente.id}`, this.proveniente)
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'atualizar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Atualização do Proveniente ' + res.id
        }
        await this.$logger(logData)
        this.$vs.notify(this.$notify.Success)
        this.modalEditarProveniente = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#proveniente-editar" + this.proveniente.id + " > .con-vs-loading"
        )
      }
    },
    async excluirProveniente () {
      await this.$vs.loading({
        container: "#proveniente-editar" + this.proveniente.id,
        scale: 0.6
      })
      try {
        await this.$http.delete(`proviniente/${this.proveniente.id}`)
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'excluir',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Exclusão do Proveniente N°' + this.proveniente.id
        }
        await this.$logger(logData)

        this.$vs.notify(this.$notify.Success)
        this.modalEditarProveniente = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$emit("update")
        await this.$vs.loading.close(
          "#proveniente-editar" + this.proveniente.id + " > .con-vs-loading"
        )
      }
    },
    async mudarStatusProveniente () {
      await this.$vs.loading({
        container: "#proveniente-editar" + this.proveniente.id,
        scale: 0.6
      })
      try {
        await this.$http.put(`proviniente/${this.proveniente.id}`, {
          lixeira: !this.proveniente.lixeira
        })
        this.$vs.notify(this.$notify.Success)
        this.modalEditarProveniente = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$emit("update")
        await this.$vs.loading.close(
          "#proveniente-editar" + this.proveniente.id + " > .con-vs-loading"
        )
      }
    },
    cancelar () {
      Object.assign(this.proveniente, this._beforeEditingCache)
      this.originalData = this._beforeEditingCache = null
      this.modalEditarProveniente = false
    }
  },
  components: components
};
</script>

<style></style>
